@media (max-width: 767.98px) {
	.app-page-title {
		padding: 0.625rem 1.5rem;
		z-index: 11 !important;
	}

	.app-page-title .page-title-wrapper {
		display: flex;
		justify-content: space-between;
	}

	.app-page-title .page-title-wrapper .page-title-heading {
		margin: 0;
		font-weight: 600;
		text-align: left;
	}

	.app-page-title .page-title-actions {
		margin: 0;
	}

	.app-page-title .page-title-actions .back-button {
		min-width: 40px;
	}

	.app-page-title .page-title-actions .back-button span {
		display: none;
	}
}
