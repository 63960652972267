$componentClass: 'pieComponent';

.#{$componentClass} {
	position: relative;
	height: 60vh;

	&__innerCircle {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
	}

	&__label {
		font-size: 1.25rem;
		font-weight: 700;
	}

	&__subLabel {
		font-size: 1rem;
		font-weight: 400;
	}

	&__action {
		margin-top: 0.5rem;
	}

	&__legend {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: 1.5rem;
	}

	&__legendItem {
		margin-right: 0.5rem;
		display: flex;
		align-items: center;
	}

	&__legendItemColor {
		width: 20px;
		height: 20px;
		display: inline-block;
		margin-right: 0.5rem;
	}
}
