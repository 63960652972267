$componentClass: 'message';

.#{$componentClass} {
	&__wrapper {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
		padding: 0.25rem 0.5rem;
		border-left: 5px solid;
		border-top: 1px solid;
		border-right: 1px solid;
		border-bottom: 1px solid;
		border-radius: 4px;

		&--default {
			border-color: #e4e4e4;
		}
		&--info {
			border-color: #30b1ff;
		}
		&--warning {
			border-color: #f7b924;
		}
		&--danger {
			border-color: #d92550;
		}
	}
	color: #000000;

	&__date {
		font-size: 11px;
		line-height: 1.1;
		margin-bottom: 0.5rem;
	}

	&__title {
		font-weight: 600;
	}

	&__content {
		line-height: 1.2;
		display: flex;
		justify-content: space-between;
	}

	&__actions {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
