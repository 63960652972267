$componentClass: 'orderDescription';

.#{$componentClass} {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&__link {
		font-size: 0.6875rem;
	}
	&__description {
		font-size: 0.9375rem;
	}
}
