$componentClass: 'technicianAssignOrdersRow';

.#{$componentClass} {
	border-bottom: 1px solid #e4e4e4;
	margin-bottom: 0.5rem;
	padding: 0.5rem 1rem 0.5rem 0;

	&__orderNumber {
		font-size: 14px;
		font-weight: bold;
	}
	&__orderType {
		font-size: 12px;
		color: #949494;
	}
	&__location {
		font-size: 12px;
		color: #005091;
	}
}
