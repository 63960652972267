$componentClass: 'orderAssignmentPlates';

.#{$componentClass} {
	display: flex;
	&__badge {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.875rem;
		margin-right: 5px;
		padding: 3px;
		width: 25px;

		&--aktiv {
			color: #5ada16;
			border: 1px solid #5ada16;
			cursor: pointer;
			background-color: #5ada161a;
		}

		&--aktiv-plus {
			color: #a940e6;
			border: 1px solid #a940e6;
			cursor: pointer;
			background-color: #a940e61a;
		}

		&--inaktiv {
			color: #cccccc;
			border: 1px solid #cccccc;
			background-color: #cccccc1a;
		}
	}
}
