$componentClass: 'orderDepartureCard';

.#{$componentClass} {
	border-left: 4px solid #005091;
	border-radius: 5px;
	margin-bottom: 2rem;
	padding: 0.5rem 1rem;
	box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(2, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
		0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);

	&__departureRow {
		border-top: 1px solid #00000028;
		padding-top: 0.5rem;
		&:first-of-type {
			border-top: none;
			padding-top: 0;
		}
	}

	&__technician {
		font-weight: 700;
		text-transform: uppercase;
		color: #005091;
	}

	&__label {
		font-size: 0.75rem;
	}
	&__value {
		font-size: 0.875rem;
		font-weight: 600;
		line-height: 1.5;
	}
}

/*
@media (max-width: 767.98px) {
	.#{$componentClass} {
	}
}
*/
