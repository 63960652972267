$componentClass: 'orderStatus';

.#{$componentClass} {
	&--undone {
		font-size: 0.75rem;
		color: #ff4d6b;
	}
	&--startedDate {
		font-size: 0.75rem;
		color: #4f9f24;
	}
	&--startedBy {
		font-size: 0.6875rem;
	}
	&--doneByTechnician {
		font-size: 0.75rem;
		color: #005091;
	}
	&--orderBegun {
		font-size: 0.75rem;
		color: #a940e6;
	}
}
