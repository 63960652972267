$componentClass: 'orderPosition';

.#{$componentClass} {
	&__listGroup {
		border: 1px solid #ececec !important;
		background-color: #ffffff;
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		margin-bottom: 1rem;
		box-shadow: 0 0.46875rem 2.1875rem rgba(8, 10, 37, 0.03), 0 0.9375rem 1.40625rem rgba(2, 10, 37, 0.03), 0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
			0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
		border-left: 4px solid #cdcdcd !important;
		border-top-right-radius: 5px !important;
		border-top-left-radius: 5px !important;
		&--done {
			border-left: 4px solid #4f9f24 !important;
			border-radius: 5px;
		}
	}

	&__headline {
		font-weight: 700;
		margin-top: 0.5rem;
		line-height: 1.2;
		margin-bottom: 0.25rem;
		color: #005091; /* #83588a;*/
		text-transform: uppercase;

		&--done {
			color: #4f9f24;
		}
	}

	&__date {
		font-weight: 600;
		color: #444444;
	}

	&__nameValue {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 2;
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		height: 100%;

		& > span {
			flex: 1;
			width: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-left: 1px solid #ececec;
			border-bottom: 1px solid #ececec;
			border-radius: 0;

			&:first-of-type {
				border-top-width: 0;
			}

			&:last-of-type {
				border-bottom-width: 0;
			}

			&.toggle {
				&--open {
					border-bottom: 1px solid #ececec;
				}
			}
			/*
			&.edit {
				background-color: lighten(#83588a, 40);
			}
			&.delete {
				background-color: lighten(#b93838, 40);
			}
			&.done {
				background-color: lighten(#4f9f24, 40);
			}
			&.toggle {
				background-color: lighten(#005091, 60);
			}
			&.download {
				background-color: lighten(#7a2626, 60);
			}
			*/
		}
	}

	&__editButtonIcon {
		color: #83588a;
		font-size: 20px !important;
	}

	&__deleteButtonIcon {
		color: #b93838;
		font-size: 20px !important;
	}

	&__doneButtonIcon {
		color: #4f9f24;
		font-size: 20px !important;
	}

	&__downloadButtonIcon {
		color: #d92929;
		font-size: 20px !important;
	}
	&__toggleButtonIcon {
		color: #005091;
		font-size: 20px !important;
	}

	&__materialRow {
		margin-top: 0.125rem;
		margin-bottom: 0.375rem;
		padding-top: 0.125rem;
		padding-bottom: 0.375rem;
		border-bottom: 1px solid #00000025;
		margin-right: 0.75rem;
		&:last-of-type {
			border-bottom: none;
		}
	}

	&__label {
		font-size: 14px;
		font-weight: 600;
		line-height: 1.25;
	}

	&__value {
		font-size: 12px;
		line-height: 1.25;
	}

	&__itemCount {
		font-size: 14px;
		font-weight: 600;
		white-space: nowrap;
	}
}

@media (min-width: 768px) {
	.#{$componentClass} {
		&__nameValue {
			margin-top: 0.75rem;
			flex-direction: column;
			line-height: 1.5;
			justify-content: flex-start;
			align-items: flex-start;
			margin-bottom: 0.5rem;

			& div:first-of-type {
				font-size: 12px;
				line-height: 1.5;
			}
			& div:last-of-type {
				font-weight: 600;
			}
		}
	}
}
