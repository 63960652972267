$componentClass: 'customerAndAddress';

.#{$componentClass} {
	&__customer {
		font-size: 0.75rem;
		font-weight: 600;
	}

	&__address {
		font-size: 0.6875rem;
	}
}
