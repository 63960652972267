$componentName: 'login';

.ibsBackground {
	background: transparent;
	background-image: url('../../../../public/images/ibs-haus-gross.jpg');
	background-repeat: no-repeat;
	background-size: cover;
}

.shadowBox {
	background: rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
}

.shadowBox .text,
.shadowBox label {
	color: #ffffff;
}

.dialogContent {
	max-height: 70vh;
	overflow-y: auto;
}

.inputField {
	border-color: rgba(255, 255, 255, 0.2);
	border-width: 1px !important;
	border-radius: 0 !important;
	/*color: #000000ad !important;*/
	/*background: #fffffffc;*/
	padding: 0.74rem 1.94rem;
}

input:-webkit-autofill {
	-webkit-text-fill-color: #495057; /* Textfarbe für Autofill */
	-webkit-box-shadow: 0 0 0px 1000px white inset; /* Hintergrundfarbe für Autofill */
}
input:focus:-webkit-autofill {
	-webkit-text-fill-color: #495057; /* Textfarbe für Autofill im Fokus */
	-webkit-box-shadow: 0 0 0px 1000px white inset; /* Hintergrundfarbe für Autofill im Fokus */
}

.staticLink {
	color: white;
	&:hover {
		color: #ffffffa0;
	}
}

a#loginButton {
	background-color: #005091;
	border-color: #005091;
	text-align: center;
	width: 100%;
	border-radius: 0;
	font-weight: 600;
	font-size: 1rem;
}

.alternate-form-feedback {
	background-color: #e2001aa8;
	color: white;
	padding: 4px 8px;
	border-radius: 4px;
}

.technicianTable .rdt_TableBody {
	min-height: 58vh;

	& .rdt_TableRow:last-of-type {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
}

::-webkit-scrollbar {
	width: 20px;
}
::-webkit-scrollbar-thumb {
	background-color: gray; /* Farbe des Scrollbalken-Griffs */
	border-radius: 8px;
}
