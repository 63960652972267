$componentClass: 'photo';

.#{$componentClass} {
	margin-right: 5px;
	margin-bottom: 5px;
	border: 1px solid #e4e4e4;
	cursor: pointer;
	display: inline-block;

	transition: all 300ms;

	&:hover {
		opacity: 0.8;
	}

	&__image {
		padding: 8px;
		border-bottom: 1px solid #e4e4e4;
	}

	&__comment {
		padding: 8px;
		font-size: 0.75rem;
		color: #888;
		text-align: center;
	}

	&__noComment {
		font-style: italic;
	}

	&__dialogComment {
		text-align: left;
		padding-top: 8px;
		font-size: 0.75rem;
		color: #888;
	}

	&__buttons {
		display: flex;
		justify-content: space-between;
	}
}
