$componentClass: 'pieTooltipComponent';

.#{$componentClass} {
	background-color: #ffffff;
	padding: 0.5rem;
	border-radius: 5px;
	box-shadow: 1px 1px 4px #0000006b;
	&__header {
		display: flex;
		align-items: center;
		margin-bottom: 0.25rem;
		padding-bottom: 0.25rem;
		border-bottom: 1px solid #c4c4c4;
	}

	&__colorCube {
		width: 20px;
		height: 20px;
		flex: 0 0 auto;
		margin-right: 0.5rem;
		border: 1px solid #777;
	}

	&__title {
		font-weight: 600;
	}

	&__type {
		font-size: 0.875rem;
		font-weight: bold;
	}

	&__value {
		display: flex;
		justify-content: space-between;
		font-size: 0.75rem;
	}

	&__status {
		font-size: 0.75rem;
		display: flex;
		justify-content: space-between;
	}

	&__state {
		font-weight: bold;

		&--done {
			color: #4f9f24;
		}

		&--undone {
			color: #f09400;
		}
	}

	&__percent {
		font-weight: 600;
	}
}
