.rdt_TableHead {
	z-index: 2 !important;
}

div[data-column-id='showAction'] {
	position: sticky;
	left: 0;
	background-color: #ffffff;
	z-index: 1;
}
