$componentClass: 'orderShow';

.#{$componentClass} {
	&__cummulatedHours {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	&__actionLink {
		flex: 1;
		padding: 1rem 0;
		border-radius: 0;
		&.position {
			& .#{$componentClass}__actionIcon {
				color: #434445; /*#6f4b75;*/
			}
		}
		&.departure {
			& .#{$componentClass}__actionIcon {
				color: #005091;
			}
		}
		&.signature {
			& .#{$componentClass}__actionIcon {
				color: #4f9f24;
			}
		}
		&.email {
			& .#{$componentClass}__actionIcon {
				color: #f5c541;
			}
		}
		&.download {
			& .#{$componentClass}__actionIcon {
				color: #005091;
			}
		}
		&.xlsupload {
			& .#{$componentClass}__actionIcon {
				color: #005091;
			}
		}
		&.reopen {
			& .#{$componentClass}__actionIcon {
				color: #f09400;
			}
		}
		&.reset {
			& .#{$componentClass}__actionIcon {
				color: #e2001a;
			}
		}
		&.items {
			& .#{$componentClass}__actionIcon {
				color: #4f9f24;
			}
		}
		&.generate {
			& .#{$componentClass}__actionIcon {
				color: #7c0674;
			}
		}
	}
	&__actionIcon {
		font-size: 24px !important;
		font-weight: 600;
		text-shadow: 1px 1px 2px #c4c4c4;
	}

	&__actionBar {
		display: flex;
		flex-direction: row;
		width: 100%;
		box-shadow: 0 3px 5px #5d825d54;
	}

	&__actionBarWrapper {
		position: sticky;
		top: 174px;
		z-index: 10;
		background-color: #ffffff;
		margin-bottom: 2rem;
	}

	& .tabs-lg-alternate .widget-number {
		font-size: 1.25rem !important;
	}

	& .tabs-lg-alternate .tab-subheading {
		padding-left: 10px;
		padding-right: 10px;
	}

	& .tabs-lg-alternate .nav.nav-justified .nav-item {
		min-height: 100%;
		height: auto;
	}
	& .tabs-lg-alternate .nav.nav-justified .nav-item .nav-link {
		min-height: 100%;
		height: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
}

@media (max-width: 767.98px) {
	.#{$componentClass} {
		&__actionBarWrapper {
			position: sticky;
			top: 132px;
			z-index: 10;
			background-color: #ffffff;
		}
	}
}
